// Overriding Default Element Behavior

ul {
  list-style: none;
}

button {
  border: 1px solid gray;
  padding: 2px 8px;
  border-radius: 15%;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav {
  position: sticky;
  top: 0;
  z-index: 1;
}

.container {
  max-width: 700px;
  min-width: 150px;
  margin: auto;
}

nav {
  ul.gray {
    border: 1px solid #e7e7e7;
    background-color: #f3f3f3;
  }

  ul.gray li a {
    color: #666;
    text-align: center;
    padding: 4px 16px;
    text-decoration: none;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  li:hover a:hover {
    color: black;
  }

  li.dropdown {
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
}

.stepped-value {
  display: flex;
  flex-direction: row;
  height: 1.5em;

  &__increment {
    background-color: green;
    color: white;
    font-weight: bold;
    font-size: medium;
  }

  &__decrement {
    background-color: red;
    color: white;
    font-weight: bold;
    font-size: medium;
  }
}

.card {
  border-radius: 5%;
  border: 1px solid darkgray;
  box-shadow: 0px 0px 5px grey;
  padding: 15px;
  margin: 20px;
  &__header {
    margin: 0;
  }
}
/* SWADE Character Creator */
.swade-character {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .card {
    margin: 20px auto;
    max-width: 400px;
  }

  .swade-attributes__item {
    display: flex;
    justify-content: space-between;
  }

  .swade-skills__item {
    display: flex;
    justify-content: space-between;
  }
  .stepped-value__display-text {
    min-width: 3rem;
    padding: 0 0.25rem;
  }
}

/* Gurps Character Creator */
.gurps-character {
  // max-width: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .card {
    margin: 20px auto;
    max-width: 300px;
  }
}

.gurps-skills {
  padding: 0;
  max-width: 300px;
  margin: auto;

  .stepped-value__display-text {
    width: 5rem;
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    // &__display-text { }
  }
}
.gurps-attributes {
  &__item {
    display: flex;
    justify-content: space-between;
    .stepped-value__display-text {
      width: 4em;
    }
  }
}

.gurps-character--pretty-print {
  &__section {
    &__header {
      margin: 15px 0 5px 0;
    }
  }
}

/* D&D Character Creator */
.dnd-character {
  .section {
    max-width: 300px;
    margin: auto;

    &__header {
      margin: 15px 0 5px 0;
    }
  }
  .stepped-value {
    justify-content: center;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  .dnd-proficiency-bonus {
    .stepped-value__display-text {
      width: 2em;
    }
  }

  .dnd-abilities {
    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .stepped-value__display-text {
        width: 3.5em;
      }
    }
  }
}
